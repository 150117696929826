import { Injectable } from "@angular/core"

import { BaThemeConfigProvider, colorHelper } from "../theme"

import { AccountService, AccountInfo } from "./account.service"
import { MapService, Bounds, ILatLong } from "./map.service"
import { getSafe, hexToRGBA, getCardValue } from "../utils/misc.utils"
import { upcomingDateDisplay, sortDateDisplay } from "../utils/date.utils"
import { convertObjectToArray } from "../utils/collections.utils"

import { BehaviorSubject } from "rxjs"
import { filter } from "rxjs/operators"

import { Chart } from "chart.js"
import * as _ from "lodash"

@Injectable()
export class DashboardService {
    public smallCards = new BehaviorSubject<any[]>([{}, {}, {}, {}])
    public halfCards = new BehaviorSubject<any[]>([{}, {}, {}])
    public quarterCards = new BehaviorSubject<any[]>([{}, {}])
    public config = new BehaviorSubject<any>({})

    private defaultDashboardColors: string[]
    private colorsets: {[key in string | number]: string[]}
    private districtBoundary: (ILatLong | ILatLong[])[] = []
    private defaultZoomSize: number
    private mapBounds: Bounds
    private labelMinZoom: number
    private showMarkerLabels: boolean

    constructor (private accountService: AccountService, private baConfig: BaThemeConfigProvider, private mapService: MapService) {
        this.accountService.selectedAccount.pipe(filter<AccountInfo>(Boolean)).subscribe(accountInfo => {
            const account = _.cloneDeep(accountInfo)
            if (getSafe(() => account.layout.Dashboard)) {
                const dash = account.layout.Dashboard

                // Get district boundary polygon and default size if available
                this.districtBoundary = mapService.getPolygonFromWKT(getSafe(() => account.layout.Configuration.DistrictBoundary))
                this.defaultZoomSize = getSafe(() => account.layout.Configuration.DefaultZoomSize)
                this.mapBounds = getSafe(() => account.layout.Configuration.MapBounds)
                this.labelMinZoom = getSafe(() => account.layout.Configuration.LabelMinZoom) || 15
                this.showMarkerLabels = getSafe(() => account.layout.Configuration.ShowMarkerLabels) || false

                // Get color settings from dashboard config, district map config, or use default theme if none is provided
                const defaultColors = baConfig.get().colors.dashboard
                this.defaultDashboardColors = (getSafe(() => dash.Config.ColorSet)) || (getSafe(() => account.layout.Configuration.MapColors)) || ["gossip", "silverTree", "surfieGreen", "blueStone", "white"].map(color => defaultColors[color])
                this.colorsets = getSafe(() => account.layout.Configuration.Colorsets) || {}
                
                const districtCards = {card1: dash.QuarterCard1, card2: dash.QuarterCard2}
                const accountCards = {
                    MediumCard1: dash.MediumCard1, MediumCard2: dash.MediumCard2, MediumCard3: dash.MediumCard3,
                    SmallCard1: dash.SmallCard1, SmallCard2: dash.SmallCard2, SmallCard3: dash.SmallCard3, SmallCard4: dash.SmallCard4
                }
    
                this.setDistrictCards(districtCards)
                this.setAccountCards(accountCards, account.data)
                this.config.next(dash.Config)
            }
        })
    }

    private setDistrictCards(data) {
        const quarterCards = (data ? [data.card1, data.card2] : [{}, {}]).map(card => {
            if (!card || !card.Data) {
                return {}
            } else {
                switch (card.Type) {
                    case "DateList": {
                        return {
                            type: card.Type,
                            title: card.Title,
                            data: convertObjectToArray(card.Data).sort((a, b) => sortDateDisplay(a && a.Date, b && b.Date)).map(item => ({
                                date: upcomingDateDisplay(item.Date),
                                item: item.Item
                            }))
                        }
                    }
                    case "ContactList": {
                        return {
                            type: card.Type,
                            title: card.Title,
                            data: convertObjectToArray(card.Data).map(contact => ({
                                contactName: contact.ContactName,
                                description: contact.Description,
                                icon: contact.Icon,
                                iconType: contact.IconType,
                                phone: contact.Phone
                            }))
                        }
                    }
                    default: {
                        return {}
                    }
                }
            }
        })
        this.quarterCards.next(quarterCards)
    }

    private setAccountCards (cards, data) {
        Chart.defaults.color = "#fff"
    
        const halfCards = (cards ? [cards.MediumCard1, cards.MediumCard2, cards.MediumCard3] : [{}, {}, {}]).sort((a, b) => window.matchMedia("(max-width: 1310px)").matches ? (a.MobileOrder || 0) - (b.MobileOrder || 0) : 0).map(card => {
            if (!card) {
                return {}
            } else {
                const mobileClass = card.MobileClass || ""
                const chartOptions = {
                    responsive: true,
                    maintainAspectRatio: false,                            
                    scales: {
                        x: {
                            ticks: {
                                color: "#fff"
                            }
                        },
                        y: {
                            title: {
                                display: Boolean(card.LabelY),
                                text: card.LabelY,
                                color: "#fff"
                            },
                            ticks: {
                                color: "#fff"
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            labels: {
                                color: "#fff"
                            }
                        }
                    }
                }

                switch (card.Type) {
                    case "Donut": {
                        const title = (card.Title && card.Title.startsWith(".")) ? data[card.Title.slice(1)] : card.Title
                        const centerText1 = (card.CenterText1 && card.CenterText1.startsWith(".")) ? data[card.CenterText1.slice(1)] : card.CenterText1
                        const centerText2 = (card.CenterText2 && card.CenterText2.startsWith(".")) ? data[card.CenterText2.slice(1)] : card.CenterText2
                        const chartData = (card.Data && card.Data.startsWith(".")) ? data[card.Data.slice(1)] : card.Data
                        const colorset = this.colorsets[card.Colorset] || this.defaultDashboardColors

                        return {
                            type: card.Type,
                            title,
                            mobileClass,
                            data: (chartData || []).map((item, index) => {
                                const colorIndex = item.TurnoutSummaryIndex === undefined ? index : item.TurnoutSummaryIndex

                                return {
                                    displayValue: item.DisplayValue,
                                    value: item.Value,
                                    color: colorset[colorIndex],
                                    highlight: colorHelper.shade(colorset[colorIndex], 15),
                                    label: item.Label,
                                    percentage: item.Percentage
                                }
                            }).filter(item => item.value > 0),
                            centerText1,
                            centerText2
                        }
                    }
                    case "Map": {
                        const title = (card.Title && card.Title.startsWith(".")) ? data[card.Title.slice(1)] : card.Title
                        const pointData: any[] = ((card.PointData && card.PointData.startsWith(".")) ? data[card.PointData.slice(1)] : card.PointData) || []
                        const polygonData: any[] = ((card.PolygonData && card.PolygonData.startsWith(".")) ? data[card.PolygonData.slice(1)] : card.PolygonData) || []
                        const pointColors = this.colorsets[card.PointColors] || this.defaultDashboardColors
                        const polygonColors = this.colorsets[card.PolygonColors] || this.defaultDashboardColors

                        return {
                            type: card.Type,
                            title,
                            mobileClass,
                            markers: pointData.reduce((arr, point) => {
                                // Make sure all markers have a valid icon and coordinates
                                const iconColor = pointColors[getCardValue(card.PointColorIndex, point)]
                                if ((iconColor || card.PointIcons) && point.Latitude && point.Longitude) {
                                    arr.push({
                                        iconColor,
                                        icon: card.PointIcons,
                                        mapLabel: this.mapService.parseMapLabel(card.PointMapLabel, point),
                                        infoLabel: this.mapService.parseMapLabel(card.PointInfoLabel, point),
                                        latitude: point.Latitude,
                                        longitude: point.Longitude
                                    })
                                }
                                return arr
                            }, []),
                            markerStyle: card.PointStyle,
                            polygons: polygonData.map(polygon => ({
                                color: polygonColors[getCardValue(card.PolygonColorIndex, polygon)] || "#7F7FFF",
                                label: this.mapService.parseMapLabel(card.PolygonLabel, polygon),
                                wktString: polygon.WKT,
                                infoLabel: this.mapService.parseMapLabel(card.PolygonInfoLabel, polygon),
                                mapLabel: this.mapService.parseMapLabel(card.PolygonMapLabel, polygon)
                            })),
                            polygonOutlineColorMatch: card.PolygonOutlineColorMatch,
                            districtBoundary: this.districtBoundary,
                            defaultZoomSize: this.defaultZoomSize,
                            mapBounds: this.mapBounds,
                            labelMinZoom: this.labelMinZoom,
                            showMarkerLabels: this.showMarkerLabels,
                            markerLabelColor: card.PointLabelColor,
                            polygonLabelColor: card.PolygonLabelColor,
                            hideButton: card.HideButton
                        }
                    }
                    case "LineAreaChart": {
                        const title = (card.Title && card.Title.startsWith(".")) ? data[card.Title.slice(1)] : card.Title
                        const chartData: any[] = ((card.DataArray && card.DataArray.startsWith(".")) ? data[card.DataArray.slice(1)] : card.DataArray) || []
                        const colorset = this.colorsets[card.Colorset] || this.defaultDashboardColors

                        return {
                            type: card.Type,
                            title,
                            mobileClass,
                            labels: chartData.map(dataPoint => dataPoint[card.LabelKey]),
                            labelY: card.LabelY,
                            data: chartData.map(dataPoint => dataPoint[card.PointKey]),
                            colors: [
                                {
                                    backgroundColor: hexToRGBA(colorset[1], 0.25),
                                    borderColor: hexToRGBA(colorset[1], 0.5)
                                }
                            ],
                            options: chartOptions
                        }
                    }
                    case "BarGraph2": {
                        const title = getCardValue(card.Title, data)
                        const chartData = getCardValue(card.DataArray, data)
                        Chart.defaults.color = "#fff"
                        const bar1Color = getSafe(() => this.colorsets[card.Colorset][0]) || "#FF6384"
                        const bar2Color = getSafe(() => this.colorsets[card.Colorset][1]) || "#FFCE56"
    
                        return {
                            type: card.Type,
                            datasets: [
                                {data: chartData.map(item => item[card.Series1Key]), label: getCardValue(card.Series1Label, data), backgroundColor: hexToRGBA(bar1Color, card.Opacity || 0.6), hoverBackgroundColor: hexToRGBA(bar1Color, card.HoverOpacity || 0.8)}, 
                                {data: chartData.map(item => item[card.Series2Key]), label: getCardValue(card.Series2Label, data), backgroundColor: hexToRGBA(bar2Color, card.Opacity || 0.6), hoverBackgroundColor: hexToRGBA(bar2Color, card.HoverOpacity || 0.8)}
                            ],
                            labels: chartData.map(item => item[card.LabelKey]),
                            options: chartOptions,
                            title,
                            mobileClass
                        }
                    }
                    default: {
                        return {}
                    }
                }
            }
        })
        this.halfCards.next(halfCards)

        const pieColor = this.baConfig.get().colors.custom.dashboardPieChart
        const smallCards = (cards ? [cards.SmallCard1, cards.SmallCard2, cards.SmallCard3, cards.SmallCard4] : [{}, {}, {}, {}]).map(card => {
            if (!card) {
                return {}
            }

            switch (card.Type) {
                case "PercentCard": {
                    const percent = (card.Percent && card.Percent.startsWith(".")) ? data[card.Percent.slice(1)] : card.Percent
                    const value = (card.Value && card.Value.startsWith(".")) ? data[card.Value.slice(1)] : card.Value

                    return {
                        type: card.Type,
                        route: card.ClickTo,
                        color:  (percent >= 100) ? "red" : ((percent >= 90) ? "yellow" : pieColor),
                        text: getCardValue(card.Text, data),
                        value,
                        rightIcon: card.RightIcon,
                        percent
                    }
                }
                case "GlyphCard": {
                    const value = (card.Value && card.Value.startsWith(".")) ? data[card.Value.slice(1)] : card.Value
                    const leftIcon = (card.LeftIcon && card.LeftIcon.startsWith(".")) ? data[card.LeftIcon.slice(1)] : card.LeftIcon

                    return {
                        type: card.Type,
                        route: card.ClickTo,
                        color: pieColor,
                        text: getCardValue(card.Text, data),
                        value,
                        rightIcon: card.RightIcon,
                        leftIcon
                    }
                }
                default: {
                    return {}
                }
            }
        })
        this.smallCards.next(smallCards)
    }
}